import React, { useEffect, useState } from 'react';
import { Grid, Popper, Typography } from '@mui/material';
import { FilterListOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DesktopDateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import List from '@mui/material/List';
import useStyles from './styles';
import CustomButton from '../../CustomButton/CustomButton';
import OptionDropdown from '../../OptionDropdown';
import {
  SEARCH_DEALS,
  SEARCH_PDF_CONTENT,
  SEARCH_REPORTS,
  SEARCH_TOPICS
} from '../../../utils/constants/stringConstants';

const ElasticSearchFilter = ({
  availableFilters,
  filterValues,
  loading,
  searchType,
  updateFilterValues,
  performSearch,
  keyCustomFields,
  restoreFilter,
  countOfAppliedFilters,
  clearFormState,
  showFilterByProduct
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      restoreFilter();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDateChange = (newDates, { validationError }, key) => {
    updateFilterValues(!!key ? 'customFieldMap' : 'selectedDates', newDates, key);
  };

  useEffect(() => {
    if (!datePickerOpen && filterValues.selectedDates.some((date) => !date)) {
      updateFilterValues('selectedDates', [null, null]);
    }
  }, [datePickerOpen]);

  useEffect(() => {
    if (searchType === SEARCH_TOPICS && !showFilterByProduct) {
      setOpen(false);
    }
  }, [searchType]);

  const handleClose = () => {
    restoreFilter();
    setOpen(false);
  };

  const handleApply = () => {
    performSearch();
    setOpen(false);
  };

  const getFiltersForDealsAndDocuments = () => (
    <List className={classes.filterList}>
      {showFilterByProduct && (
        <Grid className={classes.filterItem}>
          <Typography className={classes.filterItemTitle}>By product</Typography>
          <OptionDropdown
            loading={loading}
            disabled={loading}
            classes={classes}
            name="products"
            limitTags="true"
            inputLabelProps={{
              shrink: false
            }}
            options={availableFilters.products}
            selectedOptions={filterValues.products}
            updateOptions={(value) => updateFilterValues('products', value)}
            placeholder={
              filterValues.products && filterValues.products.length > 0 ? '' : 'Select product'
            }
            size="medium"
            disablePortal
          />
        </Grid>
      )}
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By deal name</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="deals"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.deals}
          selectedOptions={filterValues.deals}
          updateOptions={(value) => updateFilterValues('deals', value)}
          placeholder={filterValues.deals && filterValues.deals.length > 0 ? '' : 'Select deal'}
          size="medium"
          disablePortal
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By currency</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="currencies"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.currencies}
          selectedOptions={filterValues.currencies}
          updateOptions={(value) => updateFilterValues('currencies', value)}
          placeholder={
            filterValues.currencies && filterValues.currencies.length > 0 ? '' : 'Select currency'
          }
          size="medium"
          disablePortal
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By deal type</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="dealTypes"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.dealTypes}
          selectedOptions={filterValues.dealTypes}
          updateOptions={(value) => updateFilterValues('dealTypes', value)}
          placeholder={
            filterValues.dealTypes && filterValues.dealTypes.length > 0 ? '' : 'Select deal type'
          }
          size="medium"
          disablePortal
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>
          {searchType === SEARCH_DEALS ? 'By latest major doc date' : 'By execution date'}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateRangePicker
            loading={loading}
            disabled={loading}
            className={classes.datePicker}
            name="selectedDates"
            defaultRangePosition="start"
            size="medium"
            value={filterValues.selectedDates}
            onChange={handleDateChange}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            inputLabelProps={{
              shrink: false
            }}
            slotProps={{
              textField: {
                clearable: true,
                className: classes.dateInput,
                inputProps: { readOnly: true }
              }
            }}
            slots={{
              field: SingleInputDateRangeField
            }}
            format="yyyy/MM/dd"
          />
        </LocalizationProvider>
      </Grid>
      {Array.from(keyCustomFields?.entries()).map(([key, value]) =>
        value.type !== 'Date' ? (
          <Grid className={classes.filterItem}>
            <Typography className={classes.filterItemTitle}>{value.name}</Typography>
            <OptionDropdown
              loading={loading}
              disabled={loading}
              classes={classes}
              name={value.name}
              limitTags="true"
              inputLabelProps={{
                shrink: false
              }}
              options={availableFilters?.customFieldMap.get(key)?.map((name) => ({
                id: name,
                name
              }))}
              selectedOptions={filterValues.customFieldMap.get(key) || []}
              updateOptions={(values) => updateFilterValues('customFieldMap', values, key)}
              placeholder={
                filterValues.customFieldMap.has(key) &&
                filterValues.customFieldMap.get(key)?.length > 0
                  ? ''
                  : `Select ${value.name}`
              }
              size="medium"
              disablePortal
            />
          </Grid>
        ) : (
          <Grid className={classes.filterItem}>
            <Typography className={classes.filterItemTitle}>{value.name}</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDateRangePicker
                loading={loading}
                disabled={loading}
                className={classes.datePicker}
                name={value.name}
                defaultRangePosition="start"
                size="medium"
                value={filterValues.customFieldMap.get(key)}
                onChange={(newDates, validationContext) =>
                  handleDateChange(newDates, validationContext, key)
                }
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                inputLabelProps={{
                  shrink: false
                }}
                slotProps={{
                  textField: {
                    clearable: true,
                    className: classes.dateInput,
                    inputProps: { readOnly: true }
                  }
                }}
                slots={{
                  field: SingleInputDateRangeField
                }}
                format="yyyy/MM/dd"
              />
            </LocalizationProvider>
          </Grid>
        )
      )}
    </List>
  );

  const getFiltersForTopics = () => (
    <Grid className={classes.filterItem}>
      <Typography className={classes.filterItemTitle}>By product</Typography>
      <OptionDropdown
        loading={loading}
        disabled={loading}
        classes={classes}
        name="products"
        limitTags="true"
        inputLabelProps={{
          shrink: false
        }}
        options={availableFilters.products}
        selectedOptions={filterValues.products}
        updateOptions={(value) => updateFilterValues('products', value)}
        placeholder={
          filterValues.products && filterValues.products.length > 0 ? '' : 'Select product'
        }
        size="medium"
        disablePortal
      />
    </Grid>
  );

  const getFiltersForReports = () => (
    <Grid className={classes.filterList}>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By report type</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="reportTypes"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.reportTypes}
          selectedOptions={filterValues.reportTypes}
          updateOptions={(value) => updateFilterValues('reportTypes', value)}
          placeholder={
            filterValues.reportTypes && filterValues.reportTypes.length > 0
              ? ''
              : 'Select report type'
          }
          size="medium"
          disablePortal
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By report date</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateRangePicker
            loading={loading}
            disabled={loading}
            className={classes.datePicker}
            name="selectedDates"
            defaultRangePosition="start"
            size="medium"
            value={filterValues.selectedDates}
            onChange={handleDateChange}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            inputLabelProps={{
              shrink: false
            }}
            slotProps={{
              textField: {
                clearable: true,
                className: classes.dateInput,
                inputProps: { readOnly: true }
              }
            }}
            slots={{
              field: SingleInputDateRangeField
            }}
            format="yyyy/MM/dd"
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );

  const getFiltersBySearchType = () => {
    switch (searchType) {
      case SEARCH_DEALS:
        return getFiltersForDealsAndDocuments();
      case SEARCH_PDF_CONTENT:
        return getFiltersForDealsAndDocuments();
      case SEARCH_TOPICS:
        return showFilterByProduct ? getFiltersForTopics() : <div />;
      case SEARCH_REPORTS:
        return getFiltersForReports();
      default:
        return <div />;
    }
  };

  const getIconColor = () => {
    if (searchType === SEARCH_TOPICS && !showFilterByProduct) {
      return '#00000042';
    }
    return countOfAppliedFilters ? '#FFFFFF' : '#111827';
  };

  return (
    <>
      <CustomButton
        disabled={searchType === SEARCH_TOPICS && !showFilterByProduct}
        onClick={handleClickFilter}
        placeholder={countOfAppliedFilters ? `Filter +${countOfAppliedFilters}` : 'Filter'}
        variant="outlined"
        size="medium"
        leftIcon={<FilterListOutlined sx={{ fontSize: 16, color: getIconColor() }} />}
        buttonColor={countOfAppliedFilters ? 'red' : 'white'}
        textColor={countOfAppliedFilters ? 'primary' : 'secondary'}
        outlinedColor="dark-gray"
        classes={classes}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        className={classes.popper}
      >
        <div className={classes.content}>
          <div className={classes.headerGroup}>
            <FilterListOutlined sx={{ color: '#111827' }} />
            <Typography className={classes.filterTitle}>Filter</Typography>
          </div>
          {getFiltersBySearchType()}
          <div className={classes.bottomGroup}>
            <CustomButton
              onClick={clearFormState}
              placeholder="Clear All"
              variant="text"
              size="large"
              buttonColor="white"
              textColor="gray"
            />
            <div className={classes.bottomRightGroup}>
              <CustomButton
                onClick={handleClose}
                placeholder="Cancel"
                variant="text"
                size="large"
                buttonColor="white"
                textColor="gray"
              />
              <CustomButton
                disabled={loading}
                onClick={handleApply}
                placeholder="Apply"
                variant="contained"
                size="large"
                buttonColor="black"
                textColor="white"
              />
            </div>
          </div>
        </div>
      </Popper>
    </>
  );
};

ElasticSearchFilter.propTypes = {
  availableFilters: PropTypes.object,
  filterValues: PropTypes.object,
  loading: PropTypes.bool,
  searchType: PropTypes.string,
  updateFilterValues: PropTypes.func,
  performSearch: PropTypes.func,
  keyCustomFields: PropTypes.array,
  restoreFilter: PropTypes.func,
  countOfAppliedFilters: PropTypes.func,
  clearFormState: PropTypes.func,
  showFilterByProduct: PropTypes.bool
};

export default ElasticSearchFilter;
